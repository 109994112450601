<template>
<v-container fluid>
<div>
  <b-row class="mb-2">
<SiteVisit :record="currentSiteVisit" v-on="handler"/>
</b-row>
<b-row class="mb-2">
<SiteSet v-if = "enabled" :record="currentSet"  v-on="handler"/>
</b-row>

<v-tabs 
v-if ="currentSet.global_id !== ''"
v-model="tab"
  align-tabs="center"
  color="blue">
  <v-tab value="catch">Catch</v-tab>
  <v-tab value="tally">Tally</v-tab>
</v-tabs>
<v-tabs-items v-model="tab">
  <v-tab-item>
      <div class="dataentry">
          <EditableTable v-if ="currentSet.global_id !== ''"
         cols="12"
         tableName="catch"
         :value="tabTable('catch')"
         :fields="tableFields('catch')"
         :showAdd="showAdd"
         sortField="created_ts"
         v-on="handler"
          ></EditableTable>
      </div>
    </v-tab-item>
    <v-tab-item>
      <div class="dataentry">
          <EditableTable v-if ="currentSet.global_id !== ''"
         cols="12"
         tableName="tally"
         :value="tabTable('tally')"
         :fields="tableFields('tally')"
         :showAdd="showAdd"
         sortField="created_ts"
         v-on="handler"
          ></EditableTable>
      </div>
    </v-tab-item>
    </v-tabs-items>
</div>
</v-container>

</template>

<script>

import EditableTable from '../components/EditableTable.vue'
import SiteVisit from '../components/SiteVisit.vue'
import SiteSet from '../components/SiteSet.vue'
import support from '../plugins/support'

export default {
  name: "FishLab",
  components:{EditableTable,SiteVisit,SiteSet},
   
  data() {
    return {
      
      handler: {
      // findSiteVisit: this.getCurrentSiteVisit,
      // findSet: this.getCurrentSiteSet,
      submitSet: this.submitSiteSet,
      submitSiteVisit: this.submitSiteVisit,
      add_record: this.addTableItem,
      submitItem: this.saveTableItem,
    },
      selectedCell: null,
      selectedPoint: null,
      showTally: false,
      //returned:{'site_visit':[]},
      pages: ["catch", "tally"],
      showAdd:true,
      tab: null,
    };
    
  },
  computed: {
    currentSiteVisit() {
          return this.$store.getters['getCurrentRecord']('site_visit');
        },
    currentSet() {
      return this.$store.getters['getCurrentRecord']('net_set');
    },
    tally() {
      return this.$store.getters['tableData']('tally');
    },
    catchFish() {
      return this.$store.getters['tableData']('catch');
    },
   catchFields(){
    return this.$store.getters['tableFields']('catch')
   },
   tallyFields(){
    return this.$store.getters['tableFields']('tally')
   },
   enabled() {
    return ((this.currentSiteVisit.global_id != '') && (this.currentSiteVisit.global_id != undefined))
   }

  },
  created(){
  
  },
  async mounted() {
     //set table editing  
  },
  methods: {
    tableFields(tab){
      if (tab == 'tally'){
        return this.tallyFields
      }else{
        return this.catchFields
      }
    },
    tabTable(tab){
      if (tab === 'tally'){
        return this.tally
      }
        return this.catchFish
    },
   
    parseDate(){
      let date = new Date(this.currentSiteVisit.date).toDateString()
      return date;
    },
    switchTable(){
      this.showTally = !this.showTally;
    },
            
   //data entry methods
    clearTextElement: function(e) {
      this.currentLabFish[e] = "";
    },
    editCellHandler(data,fieldname){
      this.fish = this.fish.map(fish => ({...fish, isEdit:false}));
      this.fish[data.index].isEdit=true;
      this.selectedCell = fieldname
    },

    refreshPlot(){
      console.log("data change detected")
      this.fillPlotData()
    },

    async submitSiteVisit(submitInfo){
      await this.submit(submitInfo)
      .then(()=>
        { console.log("success submitting data, reloading visit")
        this.$store.commit('setSiteVisit')
        })
      .catch(
        console.log("error submitting data")
        )

    },
    async submitSiteSet(submitInfo){
      await this.submit(submitInfo)
      .then(()=>
        { console.log("success submitting data, reloading visit")
          support.getApiData('dataTables','net_set',`net_set/visit_date/${this.$store.state.visit_date}/station/${this.$store.state.station}/`
      ).then((records)=> {
          console.log(records)
          this.$store.commit('setNetSet')
        })
        })
      .catch(
        console.log("error submitting data")
        )
      
    },
    async submit(submitInfo){
      return new Promise((resolve, reject) => {
         support.submit(submitInfo.payload,submitInfo.tableName, submitInfo.endpoint)
        .then(() => {
          console.log("success submitting data, returning to sitevisit submit")
          resolve(submitInfo)})
        .catch((err) => {
          console.log(err)
          reject(submitInfo)})
        })
      },
      addNewTableItem(){
        if (this.showTally){
          this.addTableItem({tableName:'tally',record:support.newRecord('tally')})
        }else{
          this.addTableItem({tableName:'catch',record:support.newRecord('catch')})
        }
      },
      addTableItem(info){
        info.record.parent_id = this.$store.getters['getCurrentRecord']('net_set')['global_id']
        info.record.set_num= this.$store.getters['getCurrentRecord']('net_set')['set_num']
        info.record.station = this.$store.getters['getCurrentRecord']('net_set')['station']
        info.record.visit_date= this.$store.getters['getCurrentRecord']('net_set')['visit_date']
        support.submit(support.forceFieldTypes(info.tableName,[support.addMandatory(info.tableName,info.record)]), info.tableName)
        .then((result) =>{
          console.log(result)
          support.getApiData('dataTables',info.tableName,`${info.tableName}/visit_date/${this.$store.getters['attribute']('visit_date')}/station/${this.$store.getters['attribute']('station')}/set_num/${this.$store.getters['attribute']('set_num')}`)
          }
        )

  },
    saveTableItem(info){

      support.submit(support.forceFieldTypes(info.tableName,[support.addMandatory(info.tableName,info.record)]), info.tableName)
      .then((result) =>{
        console.log(result)
        support.getApiData('dataTables',info.tableName,`${info.tableName}/visit_date/${this.$store.getters['attribute']('visit_date')}/station/${this.$store.getters['attribute']('station')}/set_num/${this.$store.getters['attribute']('set_num')}`)
        }
      )
  },
  delete(tableInfo){
    support.deleteRecord(tableInfo)
  },
  

  },
}
    

</script>

<style scoped>
    .dataentry {
    display: flex;
    overflow-x: auto;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #b97b42;
}
</style>
